import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import block from 'bem-cn';

import SVGInline from 'react-svg-inline';

import Card from '../Card';

import arrowSVG from './img/arrow.svg';

import './Item.scss';

const b = block('withdrawal-requests-item-mobile');

const Item = ({ item, onResolveTopUpCard }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const [cardOpened, setCardOpened] = useState(false);

  return (
    <>
      {cardOpened && item.status === 0 && (
        <Card
          item={item}
          onClose={() => setCardOpened(false)}
          onResolveTopUpCard={onResolveTopUpCard}
        />
      )}
      <div className={b()}>
        <div className={b('item')}>
          <div className={b('item-block')}>
            <div className={b('item-label')}>{locale.id}</div>
            <div className={b('item-amount')}>{item.id}</div>
          </div>
          <div className={b('item-block')}>
            <div className={b('item-label')}>{locale.playerId}</div>
            <div className={b('item-amount')}>{item.playerId}</div>
          </div>
          <div className={b('item-block')}>
            <div className={b('item-label')}>{locale.dateCreation}</div>
            <div className={b('item-amount')}>{item.date}</div>
          </div>
          <div className={b('item-block')}>
            <div className={b('item-label')}>{locale.status}</div>
            <div className={b('item-amount')}>
              {locale.withdrawalRequestHistoryStatuses[item.status]}
            </div>
          </div>
          <div className={b('item-block')}>
            <div className={b('item-label')}>{locale.amount}</div>
            <div className={b('item-amount', { color: 'yellow' })}>
              {item.amount}
            </div>
          </div>
          <div className={b('item-button')}>
            {item.status === 0 ? (
              <div
                className={b('item-button-block')}
                onClick={() => setCardOpened(!cardOpened)}
              >
                <p className={b('item-button-title')}>{locale.redact}</p>
                <SVGInline className={b('item-button-arrow')} svg={arrowSVG} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Item;
